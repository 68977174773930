import axios from 'axios'
import Vue from "vue";
import store from '@/store'
// 添加请求拦截器


const http = axios.create({
    headers: {
        Authorization: `app_guid=ec962e33e642a6e5`,

    },
    baseURL: "https://www.eqscloud.com/"
})

http.interceptors.request.use(config => {
    config.headers.token = localStorage.getItem("token") || ""
    return config;
}, error => {
    console.log("触发请求拦截错误");
    return Promise.reject(error);
});

// 添加响应拦截器
http.interceptors.response.use(response => {
    if (response.data.code !== 200) {
        // Vue.$toast.error(`接口数据响应错误\nApi：${response.config.url}\n错误代码：${response.data.code}\n错误消息：${response.data.msg}`)
        Vue.$toast.warning(`${response.data.msg}`)
        return Promise.reject(response.data);
    } else {
        return response.data;
    }
}, error => {

    // response.status超出 2xx 范围的状态码都会触发该函数。
    if (error.config && error.config.url) {
        // Vue.$toast.error(`请求接口数据响应错误\nApi：${error.config.url}\n错误代码：${error.response.data.code}\n错误消息：${error.response.data.msg}`)
        if (error.response.data.code === 401) store.dispatch("clearUserInfo")//token过期
    } else {
        console.log(error);
        // Vue.$toast.error(`请求服务器错误`)
    }
    return Promise.reject(error);
});

export default http