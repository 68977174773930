<template>
    <el-dialog class="customDialog" @closed="$emit('close')" append-to-body center :visible.sync="dialogVisible" destroy-on-close :show-close="false">
        <div class="loginWindow">
            <div class="title">账号密码登录</div>
            <el-input class="input username" placeholder="账号" v-model="form.account"></el-input>
            <el-input class="input password" placeholder="密码" v-model="form.password" show-password></el-input>
            <div class="ForgetPassword" @click="ForgetPassword">忘记密码</div>
            <el-button class="loginBtn" type="text" round @click="login" :loading="loading">登录</el-button>
        </div>
    </el-dialog>
</template>

<script>
    import axios from '@/axios/indexZX'
    export default {

        data() {
            return {
                dialogVisible: false,
                loading: false,
                form: {
                    account: "",
                    password: ""
                }

            };
        },
        watch: {},
        computed: {},
        created() {},
        mounted() {
            this.dialogVisible = true
        },
        methods: {
            ForgetPassword() {
                this.$router.push({ name: "forgetPassword" })
                this.$emit('close')
                // const { href } = this.$router.resolve({ name: "forgetPassword", query: { id: 1 } });
                // window.open(href, '_blank');
            },
            async login() {
                try {
                    this.loading = true
                    await this.$store.dispatch("login", this.form)
                    this.$emit('close')
                } finally {
                    this.loading = false
                }

            }
        },
    };
</script>

<style scoped lang="less">
    .customDialog {
        //自定义弹窗
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;

        /deep/ .el-dialog__header {
            display: none;
        }

        /deep/ .el-dialog--center {
            margin: 0 !important;
            width: unset;
            background: unset !important
        }

        /deep/ .el-dialog__body {
            padding: 0;
        }
    }

    .loginWindow {
        width: 400px;
        height: 414px;
        background: #FFFFFF;
        box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.1600);
        border-radius: 10px 10px 10px 10px;
        padding: 40px 48px 48px 48px;
        box-sizing: border-box;

        .title {
            font-size: 26px;
            font-weight: 400;
            color: #1ECDA4;
            text-align: center;
            position: relative;



            &::after {
                position: absolute;
                content: "";
                width: 40px;
                height: 3px;
                background: #1ECDA4;
                border-radius: 4px 4px 4px 4px;
                opacity: 0.96;
                bottom: -8px;
                left: 131px;
            }
        }

        .input {
            font-size: 16px;

            /deep/ .el-input__inner {
                border: 0;
                border-bottom: 1px solid rgba(238, 238, 238, 1);
                padding-bottom: 16px;
                padding-left: 0;
                border-radius: 0 !important;
            }
        }

        .username {
            margin-top: 55px;
        }

        .password {
            margin-top: 40px;
        }

        .ForgetPassword {
            font-size: 16px;
            font-weight: 400;
            color: #1ECDA4;
            margin-top: 16px;
            text-align: right;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }

        .loginBtn {
            margin-top: 40px;
            width: 100%;
            background: #1ECDA4;
            color: #FFFFFF;
        }

    }
</style>