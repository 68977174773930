<template>
    <div class="statement" :style="{'background-color':$route.meta.bottomBackgroundColor||''}">版权所有 ◎ 2022 南京知麦信息科技有限公司<span style="margin:0  10px"></span><a href="https://beian.miit.gov.cn/" target="_blank" class="link-normal"  style="color: #666666;">苏ICP备20039445号-5</a> <span style="margin:0  10px"></span>区块链信息服务备案号：<a class="link-normal" href="https://bcbeian.ifcert.cn/" target="_blank" style="color: #666666;">苏网信备32010222590907670021号</a></div>
</template>

<script>
    export default {

        data() {
            return {

            };
        },
        watch: {

        },
        async mounted() {
            window.addEventListener(
                "message",
                (event) => {
                    // 根据接收的消息隐藏对应元素
                    if (event.data.footer) {
                        const footer = document.querySelector(".statement");
                        footer.style.display = "none";
                    }
                },
                false
            );
        },
        created() {}
    }
</script>


<style scoped lang="less">
.link-normal{
    text-decoration: none;
}
    .statement {
        width: 100%;
        min-width: 1200px;
        height: 48px;
        line-height: 48px;
        background: #F9F9F9;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        text-align: center;
    }
</style>