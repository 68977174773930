import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import '@/assets/css/global.less'
import '@/assets/css/iconfont_tq.css'

import dataV from '@jiaminghi/data-view'
Vue.use(dataV)

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
Vue.use(Toast);

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import animate from 'animate.css'
Vue.use(animate)


Vue.config.productionTip = false;

// let setFont = () => {
//   let designSize = 3840; // 设计图尺寸
//   let wW = document.body.scrollWidth; //网页宽度
//   let hH = document.body.scrollHeight; //网页高度
//   let size = (wW * 200) / designSize; //1rem的大小
//   document.documentElement.style.fontSize = size + 'px';
//   store.commit("SET_onresizeNumber", wW + hH)
//   if (!window.onresize) window.onresize = setFont
// }
// setFont()


new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
