<template>
    <el-scrollbar class="mainContainer">
        <!-- <el-scrollbar class="mainContainer" element-loading-background="rgba(255, 255, 255, 0.8)"  element-loading-text="登陆中..." v-loading="loading"> -->
        <Header></Header>
        <div class="container">
            <!-- <transition name="fade-transform" mode="out-in"> -->
            <router-view></router-view>
            <!-- </transition> -->
        </div>
        <Bottom></Bottom>
        <el-image @click="$router.push({name:'home'})" v-if="$route.name!='home'" class="goHome" :src="require('@/assets/image/goHome.png')"></el-image>
    </el-scrollbar>
</template>

<script>
    import Header from '@/components/header.vue'
    import Bottom from '@/components/bottom.vue'
    export default {
        components: { Header, Bottom },
        data() {
            return {
                loading: true
            }
        },
        async created() {
            let token = localStorage.getItem("token")
            if (token && !this.$store.state.userInfo.id) await this.$store.dispatch("tokenLogin") //有token 没有用户信息
            this.loading = false
        }
    }
</script>

<style scoped lang="less">
    .mainContainer {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .container {
        min-width: 1200px;
        overflow-x: hidden;
    }

    // 进入退出动画
    .fade-transform-leave-active,
    .fade-transform-enter-active {
        transition: all .3s;
    }

    .fade-transform-enter {
        opacity: 0;
        transform: translateX(-30px);
    }

    .fade-transform-leave-to {
        opacity: 0;
        transform: translateX(30px);
    }

    .goHome {
        position: fixed;
        right: 10px;
        bottom: 50px;
    }
</style>