<template>
    <div class="headerContainer" :style="{'background-color':'rgba(30, 205, 164,'+($route.name==='home'?opacity:'1') + ')','position':$route.name==='home'?'fixed':'sticky'}">
        <div class="headerRow">
            <div class="left">
                <el-image v-if="!flag" @click="$router.push({name:'home'})" class="logo" style="width: 70px;cursor: pointer;" :src="require('@/assets/image/logo.png')" fit="fill"></el-image>
                <el-dropdown class="menuContainer" trigger="click" @command="handleCommand">
                    <div style="display:flex;   align-items: center; cursor: pointer;">
                        <img src="../assets/image/menu.png">
                        <div>{{$route.meta.pageName}}</div>
                    </div>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="home" :class="[actPageName==='home'?'act':'']">实时上链状态</el-dropdown-item>
                        <el-dropdown-item command="page1" :class="[actPageName==='page1'?'act':'']">节点拓扑图</el-dropdown-item>
                        <el-dropdown-item command="page2" :class="[actPageName==='page2'?'act':'']">节点运行状态</el-dropdown-item>
                        <el-dropdown-item command="page3" :class="[actPageName==='page3'?'act':'']">近7天上链情况</el-dropdown-item>
                        <el-dropdown-item command="page4" :class="[actPageName==='page4'?'act':'']">上链接入企业</el-dropdown-item>
                        <el-dropdown-item command="page5" :class="[actPageName==='page5'?'act':'']">区块链查询分布</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <template v-if="!flag">
                <el-dropdown v-if="userInfo.id" trigger="click" @command="handleCommand1">
                    <el-button round class="loginBtn" size="medium">
                        <div class="logOn">
                            <el-avatar v-if="userInfo.avatar" :src="'https://www.eqscloud.com'+userInfo.avatar" :size="20"></el-avatar>
                            <el-avatar v-else icon="el-icon-user-solid" :size="20"></el-avatar>
                            <div class="userName">{{userInfo.nickname||userInfo.username}}</div>
                        </div>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="logOut">退出登录</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                <el-button v-else @click="loginVisible=true" round class="loginBtn" size="medium">登录</el-button>
            </template>
            <Login v-if="loginVisible" @close="loginVisible=false"></Login>
        </div>
    </div>
</template>
<script>
    import Login from '@/components/login.vue'
    import { mapGetters } from 'vuex'
    export default {
        computed: {
            ...mapGetters(['userInfo'])
        },
        components: { Login },
        watch: {
            $route(n, o) {
                this.actPageName = n.name

            }
        },
        data() {
            return {
                opacity: 0,
                actPageName: this.$route.name,
                loginVisible: false,
                flag: true, //艳峰  后期优化
                flagnum: 1,
            }
        },
        beforeCreate(){
            window.addEventListener(
                "message",
                (event) => {
                    // 根据接收的消息隐藏对应元素
                    if (event.data.footer) {
                        this.flag = true
                        this.flagnum = 0
                    }
                },
                false
            );
            
        },
        mounted() {
            setTimeout(()=>{
                console.log(this.flagnum)
                if(this.flagnum == 1) {
                    this.flag = false
                }
            }, 500)
            window.addEventListener('scroll', this.handleScroll, true)
        },
        methods: {
            handleScroll(e) {
                if (this.$route.name !== 'home') return
                let opacity = e.target.scrollTop / 80
                this.opacity = opacity
            },
            jump(name) {

            },
            handleCommand(command) {
                this.$router.push({ name: command })
            },
            handleCommand1(command) {
                if (command === 'logOut') this.$store.dispatch("logOut")
            }
        },
    }
</script>

<style scoped lang="less">
    /deep/ .el-dropdown-menu__item:not(.is-disabled):hover {
        background-color: rgba(30, 205, 164, 0.1);
        color: rgba(51, 51, 51, 1);
    }



    /deep/ .el-dropdown-menu__item {
        &.act {
            color: rgba(30, 205, 164, 1) !important;
        }
    }

    .headerContainer {
        min-width: 1200px;
        width: 100%;

        top: 0;
        left: 0;
        z-index: 2;
    }

    .headerRow {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 1200px;
        margin: 0 auto;
        height: 80px;

        .left {
            display: flex;
            align-items: center;



            .menuContainer {
                margin-left: 56px;
                font-size: 18px;
                font-weight: 400;
                color: #FFFFFF;
                display: flex;
                align-items: center;

                img {
                    cursor: pointer;
                    width: 20px;
                    margin-right: 16px;
                }


            }

        }

        .loginBtn {
            background: rgba(255, 255, 255, 0.2000);
            border: 1px solid #FFFFFF;
            color: #FFFFFF;
            padding: 0 12px;
            text-align: center;
            height: 32px;
            line-height: 32px;

            .logOn {
                display: flex;
                align-items: center;
                justify-content: center;

                .userName {

                    margin-left: 8px;
                    font-size: 14px;
                    font-weight: 400;
                }
            }
        }
    }
</style>