import Vue from "vue";
import Vuex from "vuex";
import axiosZX from '@/axios/indexZX'
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    networkName:"zmchain",
    userInfo: {},
    onresizeNumber: 0
  },
  mutations: {
    SET_onresizeNumber: (state, onresizeNumber) => state.onresizeNumber = onresizeNumber,
    SET_userInfo: (state, userInfo) => state.userInfo = userInfo,

  },
  actions: {
    setUserInfo({ commit }, userInfo) {
      commit("SET_userInfo", userInfo)
      localStorage.setItem("token", userInfo.token)
    },
    async login({ commit, dispatch }, formData = {}) {//账号和密码登录
      let { data, time } = await axiosZX.request({
        url: "api/user/login",
        method: "POST",
        data: formData
      })
      dispatch('setUserInfo', data.userinfo)
    },
    async logOut({ commit }) {//安全退出
      await axiosZX.request({
        url: "api/user/logout",
        method: "POST"
      })
      localStorage.removeItem("token")
      commit("SET_userInfo", {})
    },
    async tokenLogin({ commit, dispatch }) {//token登录
      let { data, code, time } = await axiosZX.request({
        url: "api/user/get_user_info",
        method: "POST"
      })
      dispatch("setUserInfo", data)
    },
    clearUserInfo({commit}) {
      commit("SET_userInfo", {})
      localStorage.removeItem("token")
    }
  },
  modules: {},
  getters: {
    userInfo: state => state.userInfo
  }
});
