import Vue from "vue";
import VueRouter from "vue-router";
import store from '@/store'
import axiosZX from '@/axios/indexZX'
import { Loading } from 'element-ui';

// 屏蔽 push 重复路由报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.replace = function replace(location) {
  return originalPush.call(this, location).
    catch(error => {
      if (error.name !== 'NavigationDuplicated') { // 屏蔽 push 相同路由的报错
        throw error;
      } else {
        console.warn(`重复路由：${error.name}`);
      }
    });
}
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).
    catch(error => {
      if (error.name !== 'NavigationDuplicated') { // 屏蔽 push 相同路由的报错
        throw error;
      } else {
        console.warn(`重复路由：${error.name}`);
      }
    });
}

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/home.vue"),
    meta: {
      pageName: '实时上链状态',
      bottomBackgroundColor: "#F9F9F9"
    }
  },
  {
    path: "/forgetPassword",
    name: "forgetPassword",
    component: () => import("@/views/forgetPassword.vue"),
    meta: {
      pageName: '忘记密码'
    }
  },
  {
    path: "/page1",
    name: "page1",
    component: () => import("@/views/page1.vue"),
    meta: {
      pageName: '节点拓扑图'
    }
  },
  {
    path: "/page2",
    name: "page2",
    component: () => import("@/views/page2.vue"),
    meta: {
      pageName: '节点运行状态'
    }
  },
  {
    path: "/page3",
    name: "page3",
    component: () => import("@/views/page3.vue"),
    meta: {
      pageName: '近7天上链情况'
    }
  },
  {
    path: "/page4",
    name: "page4",
    component: () => import("@/views/page4.vue"),
    meta: {
      pageName: '上链接入企业'
    }
  },
  {
    path: "/page5",
    name: "page5",
    component: () => import("@/views/page5.vue"),
    meta: {
      pageName: '区块链查询分布'
    }
  },
  {
    path: "/searchResult",
    name: "searchResult",
    component: () => import("@/views/searchResult/index.vue"),
    meta: {
      pageName: '查询结果',
      bottomBackgroundColor: "#FDFAFE"
    }
  },
  {
    path: "/test",
    name: "test",
    component: () => import("@/views/test.vue"),
    meta: {
      pageName: 'test'
    }
  }



];




const router = new VueRouter({
  routes,
});



router.beforeEach(async (to, from, next) => {
  next()
})



export default router;
